import { TitleDivider } from 'components/TitleDivider'
import { VideoPlayer } from 'components/VideoPlayer'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section, { SECTION_VARIANTS } from 'components/Section'
import React from 'react'
import styles from './AboutUsVideo.module.scss'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { Typography } from 'components/Contentful'
import { StatGrid } from 'components/StatGrid'

function AboutUsVideo({ content }) {
  const { eyebrow, title, subtitle, disclaimer, videoPlayer, grid, config } =
    useMemoizedContentGetter(content, [
      'eyebrow',
      'title',
      'subtitle',
      'disclaimer',
      'videoPlayer',
      'grid',
      'config',
    ])

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const variant = {
    title: isMobile ? 'headingMd' : 'displayLg',
    titleFontWeight: 'medium',
    subtitle: isMobile ? 'bodySm' : 'displaySm',
    subtitleFontWeight: 'regular',
  }

  const variantBg = config?.jsonContent?.sectionVariant ?? SECTION_VARIANTS.bgPrimaryHighlightBlue

  return (
    <Section
      className={styles['section']}
      variant={variantBg}
    >
      <TitleDivider
        eyebrowBlack={variantBg !== SECTION_VARIANTS.bgBlue100}
        title={title}
        eyebrow={eyebrow}
        subtitle={grid ? null : subtitle}
        isMobile={isMobile}
        variants={variant}
      />
      <VideoPlayer content={videoPlayer} aspectRatio={16 / 9} />
      {subtitle && (
        <Typography
          data-testid="title-divider-subtitle"
          className={styles['subtitle']}
          content={subtitle.textContent}
          variant={isMobile ? 'bodySm' : 'bodyLg'}
        />
      )}
      {grid && <StatGrid grid={grid} />}
      {disclaimer && (
        <Typography
          data-testid="title-divider-disclaimer"
          className={styles['disclaimer']}
          content={disclaimer.textContent}
          variant="bodyXs"
        />
      )}
    </Section>
  )
}
export { AboutUsVideo }
export default AboutUsVideo
